<template>
    <div class="mb-4">
        <vue-dropzone
            ref="myVueDropzone"
            id="dropzone"
            :options="dropzoneOptions"
            :duplicateCheck="true"
            :useCustomSlot="true"
            :includeStyling="false"
        >
            <div class="dropzone-custom-content">
                <h3 class="dropzone-custom-title">{{$t('Drag and drop to upload content!')}}</h3>
                <div class="subtitle">{{$t('...or click to select a file from your computer')}}</div>
            </div>
        </vue-dropzone>
    </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'


export default {
    props: ['type', 'params', 'afterUpload'],
    components: {
        vueDropzone: vue2Dropzone
    },
    data() {
        let _this = this;
        return {
            src: null,
            dropzoneOptions: {
                previewTemplate: this.template(),
                paramName: "uri",
                params: this.uploadParams,
                uploadMiltiple: true,
                autoProcessQueue: true,
                url: process.env.VUE_APP_API_ENDPOINT + '/uploads',
                thumbnailWidth: 48,
                thumbnailHeight: 48,
                maxFilesize: 100000,
                maxFiles: 10000,
                addRemoveLinks: false,
                acceptedFiles: this.type ? this.type : 'image/*',
                headers: {
                    'Authorization': 'Bearer ' + window.localStorage['feathers-jwt']
                },

                init: function () {
                    this.on('success', (file, message) => {
                        if (_this.afterUpload) {
                            _this.afterUpload(file, message);
                        }
                    });

                    this.on('error', (file, message) => {
                        console.log('error', message);
                    });
                    this.on('error-multiple', (file, message) => {
                        console.log('error-multiple', message);
                    });
                    this.on('sending', (file) => {
                        // console.log('sending-multiple');
                    });
                    this.on('cancelled', (file) => {
                        console.log('cancelled');
                    });
                    this.on('complete', (file) => {
                        this.removeFile(file);

                    });
                    this.on('addedfile', (file) => {

                        // const css = file.previewElement.querySelectorAll('.avatar-img');
                        // console.log(' css[0].style.objectFit', css[0].style.cssText);
                        // css[0].style.objectFit = 'cover';

                        // object-fit
                        this.processQueue();
                    });
                    this.on('uploadprogress', function (file, progress) {
                        // console.log('progresss', progress, file);

                    });
                }
            }
        }
    },

    mounted() {

        this.$root.$on('upload-image', (image) => {

            function b64toBlob(b64Data, contentType, sliceSize) {
                contentType = contentType || '';
                sliceSize = sliceSize || 512;

                var byteCharacters = atob(b64Data);
                var byteArrays = [];

                for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                    var slice = byteCharacters.slice(offset, offset + sliceSize);

                    var byteNumbers = new Array(slice.length);
                    for (var i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }

                    var byteArray = new Uint8Array(byteNumbers);

                    byteArrays.push(byteArray);
                }

                var blob = new Blob(byteArrays, { type: contentType });
                return blob;
            }
            const block = image.split(";");
            // Get the content type of the image
            const contentType = block[0].split(":")[1];// In this case "image/gif"
            // get the real base64 content of the file
            const realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."

            // Convert it to a blob to upload
            const blob = b64toBlob(realData, contentType);

            const file = new File([blob], "Icon.jpg", {
                type: contentType
            });
            file.accepted = true;
            file.status = "queued";
            file.dataURL = image;
            file.width = 640;
            file.height = 480;
            file.upload = {
                filename: 'Another.jpg',
                totalChunkCount: 1,
                chunked: false
            }

            this.$refs.myVueDropzone.manuallyAddFile(
                file,
                image,
            );

            this.$refs.myVueDropzone.processQueue();
        });
    },
    methods: {

        uploadParams() {
            return this.params;
        },
        template() {
            return `
            <div class="card mt-2 mb-0">
                <div class="card-body p-1">
                    <ul class="dz-preview dz-preview-multiple list-group list-group-lg list-group-flush">
                        <li class="list-group-item p-0">
                        
                            <div class="row align-items-center">
                                <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                                <div class="col-auto">
                                    <div class="avatar">
                                        <img class="avatar-img rounded" src="..." alt="..." data-dz-thumbnail>
                                    </div>
                                    
                                </div>
                                <div class="col ml-n3">
                                    <h4 class="mb-1" data-dz-name>...</h4>
                                    <p class="small text-muted mb-0" data-dz-size>...</p>
                                    <small class="dz-error-message text-danger"><span data-dz-errormessage></span></small>

                                    <div class="progress progress-sm">
                                        <div class="progress-bar" role="progressbar" data-dz-uploadprogress aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <button class="btn btn-white" class="dropdown-item" data-dz-remove>
                                        <span class="fe fe-trash"></span>
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        `;
        }
    }

}
</script>
