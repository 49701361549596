<template>
    <div class="avatar" :class="size">
        <img v-if="src" :src="src" :width="width" v-b-tooltip :title="title" class="avatar-img" :class="typeClass" />
        <div v-else v-b-tooltip :title="title" class="text-center border border-1" :class="typeClass">
            <span class="fe text-muted fe-image" :class="hsize"></span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['src', 'width', 'type', 'size', 'title', 'color'],
    computed: {
        hsize() {
            if (this.size == 'avatar-xs') return 'h4';
            return 'h1';
        },
        typeClass() {
            let maps = {
                'rounded': 'rounded',
                'circle': 'rounded-circle',
            }
            return maps[this.type] || '';
        },

    }
}

</script>

<style>

</style>
