<template>
    <modal :title="title" :id="id" :actionButtons="false" size="lg" :closeButton="true" scrollable @hidden="onStop">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="border">
                        <web-cam
                            ref="webcam"
                            :device-id="deviceId"
                            @started="onStarted"
                            @stopped="onStopped"
                            @error="onError"
                            @cameras="onCameras"
                            @camera-change="onCameraChange"
                        />
                    </div>

                    <div class="row mt-2">
                        <div class="col-md-12" v-if="devices.length>1">
                            <select v-model="camera" class="form-control">
                                <option>{{$t('Select Device')}}</option>
                                <option
                                    v-for="device in devices"
                                    :key="device.deviceId"
                                    :value="device.deviceId"
                                >{{ device.label }}</option>
                            </select>
                        </div>
                        <div class="col-md-12 mt-2">
                            <div class="row">
                                <!-- <div class="col">
                                    <button type="button" class="btn btn-primary" @click="onStart">Start camera</button>
                                </div>
                                <div class="col">
                                    <button type="button" class="btn btn-danger" @click="onStop">Stop Camera</button>
                                </div>-->
                                <div class="col">
                                    <button type="button" class="btn btn-success" @click="onCapture">
                                        <span class="fe fe-camera"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-6" v-for="(image, index) in images" :key="index">
                            <div class="card">
                                <img class="card-img-top" :src="image" />
                                <div class="card-body p-2">
                                    <div class="row align-items-center">
                                        <div class="col">
                                            <button class="btn btn-white" @click="remove(index)">
                                                <span class="fe fe-trash"></span>
                                            </button>
                                        </div>
                                        <div class="col-auto">
                                            <button class="btn btn-white" @click="use(index)">
                                                <span class="fe fe-check"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <!-- / .card-body -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import Avatar from "@/components/Avatar.vue";
// import webcam from "vue-web-cam/src/webcam.vue";
import WebCam from '@/components/WebCam.vue';
import { find, head } from "lodash";
export default {
    name: "WebCamModule",
    props: ['id', 'model'],
    components: {
        Modal,
        WebCam,
        Avatar
    },
    data() {
        return {
            img: null,
            camera: null,
            deviceId: null,
            devices: [],
            images: [],
            errors: null,
            hideForm: false,
        };
    },
    computed: {
        device: function () {
            return find(this.devices, n => n.deviceId == this.deviceId);
        },
        title() {
            if (!this.device) {
                return 'Camera not found';
            }
            return this.device.label;
        }
    },
    watch: {
        camera: function (id) {
            this.deviceId = id;
        },
        devices: function () {

            // Once we have a list select the first one
            let first = head(this.devices);
            if (first) {
                console.log('devices', first);
                this.camera = first.deviceId;
                this.deviceId = first.deviceId;
            }
        }
    },
    created() {
        this.$root.$on('bv::hide::modal', (modal) => {

            if (modal !== this.id) return;
            this.onStop();
            this.images = [];
        })
    },
    methods: {
        remove(index) {
            this.images.splice(index, 1);
        },

        use(index) {
            this.$root.$emit('upload-image', this.images[index]);
            this.$root.$emit('bv::hide::modal', this.id);
        },
        onCapture() {
            this.images.push(this.$refs.webcam.capture());
        },
        onStarted(stream) {
            console.log("On Started Event", stream);
        },
        onStopped(stream) {
            console.log("On Stopped Event", stream);
            this.deviceId = null;
            this.devices = [];
        },
        onStop() {
            this.$refs.webcam.stop();
        },
        onStart() {
            console.log('on Start method');
            this.$refs.webcam.start();
        },
        onError(error) {
            console.log("On Error Event", error);
        },
        onCameras(cameras) {
            this.devices = cameras;
            console.log("On Cameras Event", cameras);
        },
        onCameraChange(deviceId) {
            this.deviceId = deviceId;
            this.camera = deviceId;
            // console.log("On Camera Change Event", deviceId);
        }
    }
};
</script>
<style>
video{
    height: auto;
}
</style>
